<script>
export default {
  data() {
    return {
      fileList: [],
    };
  },
};
</script>

<template>
  <div>
    <div>vant: 2.13.2</div>
    <div>vue: 2.7.16</div>
    <van-uploader v-model="fileList" multiple />
  </div>
</template>
